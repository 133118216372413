<template>
<div id="app">
  <router-view/>
  <!-- <button @click="takeScreenshot">Сделать скриншот</button> -->
  <div v-if="myCookie">
    <div class="notification">
      <transition-group name="transition-animate">
        <div v-for="item in push_notifications" :key="item.object_id" class="notification-content">
          <div class="content-text">
            <span>{{ item.notification_text }}</span>
          </div>
        </div>
      </transition-group>

      <transition-group name="transition-animate">
        <div v-for="item in notificationList" :key="item" class="notification-ws-item notification-content" v-show="!item.is_checked">
          <span> {{ item.type }} </span>
          <div>{{ item.title }}</div>
          <button @click="acceptNotification(item); item.is_checked = true;">Подтвердить</button>
        </div>
      </transition-group>
    </div>
  </div>
</div>
</template>

<script>
/* eslint-disable */
import { getUserNotifications, getTaskById } from '@/api/get'
import { refreshTocken } from '@/api/post'
import { patchPushNotification, patchCheckNotification } from '@/api/patch'
import notificationSound from '@/assets/audio/notification.mp3'
import store from './store'
import html2canvas from 'html2canvas'
// import VueCookies from 'vue-cookies'
import { jwtDecode } from "jwt-decode";
import router from './router'

// import { registerServiceWorker } from '@/main.js';
// import serviceWorker from '../public/serviceWorker.js';
import * as sodium from 'libsodium-wrappers';

export default {
  name: 'app',
  data () {
    return {
      userId: this.$cookies.get('ID'),
      myCookie: this.$cookies.get('JWT'),
      coock: this.$cookies,
      center_window: false,
      modal_window: false,
      notifications: [],
      audioNotifications: [],
      push_notifications: [],
      new_notifications: [],
      interval: null,
      visibleAudioNotification: false,
      audioWs: null,
      isRecording: false,
      audioUrl: null,
      audioChunks: [],
      audioBlob: null,
      audioBlobBase64: null,
      recordingState: null,
      transcript: null,
      wsRecordUser: null,
      notificationText: null,
      notifyWs: null,
      window_instruction: false,
      showInstruction: true,
      deadline_tasks: [],
      blob_file: '',
      blob: null,
      blobUrl: null,
      blobArr: null,
      notificationList: [],
      mouseX: 0,
      mouseY: 0,
      mouseXPrev: 1,
      mouseYPrev: 1,
      notificationWs: null,
      notificationJoinOrganizations: [],
      socket: null,
      tokenLifetime: null,
      testData: '',

      wsOnlineUsers: null,
      onlineUsers: []
    }
  },
  methods: {
    check_API () {
      getUserNotifications().then((response) => {
        this.notifications = response
        this.push_notifications = this.notifications.filter((item) => !item.is_pushed)
        this.new_notifications = this.notifications.filter((item) => !item.is_checked)
        this.notificationJoinOrganizations = this.notifications.filter((item) => item.notification_reason === 'NEW_MEMBER')
        // if(data.notification_reason == 'NEW_MEMBER') {
        //   this.notificationJoinOrganizations.push(data)
        // }

        // Создаем копию массива уведомлений для безопасной итерации
        const unprocessedNotifications = [...this.push_notifications]

        // Проходим по каждому уведомлению
        unprocessedNotifications.forEach((notification) => {
          setTimeout(() => {
            if (this.push_notifications.some((item) => item.id === notification.id)) {
              // Обновляем статус уведомления
              patchPushNotification(notification.id).then(() => {
                this.push_notifications = this.push_notifications.filter((item) => item.id !== notification.id)
                const audio = new Audio(notificationSound)
                audio.play()
              })
            }
          }, 1000)
        })
      })
    },

    updateMousePosition(event) {
      this.mouseX = event.pageX;
      this.mouseY = event.pageY;
    },
    checkActiveCursor() {
      if(this.mouseX == this.mouseXPrev && this.mouseY == this.mouseYPrev) console.log('Пользователь не активен')
      this.mouseXPrev = this.mouseX
      this.mouseYPrev = this.mouseY
    },
    takeScreenshot() {
      console.log(document)
      html2canvas(document.querySelector('#app')).then(canvas => {
      // html2canvas(document.querySelector('#screenshot-target')).then(canvas => {
        console.log(canvas)
        const image = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = image;
        link.download = 'screenshot.png';
        link.click();
      });
    },

    playAudio (audio) {
      console.log(audio)
      audio.crossOrigin = 'anonymous'
      audio.play()
    },
    audioNotificationCheck (item) {
      this.blob = null
      this.blobArr = null
      this.audioWs.send({
        str: this.audioBlobBase64,
        bool: true
      })
    },
    async notificationWsMethod () {
      const sodium = require('libsodium-wrappers');
      
      const key = 'keySmrtsft'.padEnd(32, '0')
      let message = this.myCookie;
      
      (async () => {
        await sodium.ready;
        const salt = sodium.randombytes_buf(sodium.crypto_secretbox_NONCEBYTES);
        const iv = sodium.randombytes_buf(sodium.crypto_secretbox_NONCEBYTES);
        const cipherText = sodium.crypto_secretbox_easy(message, iv, key);
        const saltBase64 = sodium.to_base64(salt, sodium.base64_variants.ORIGINAL);
        const ivBase64 = sodium.to_base64(iv, sodium.base64_variants.ORIGINAL);
        const cipherTextBase64 = sodium.to_base64(cipherText, sodium.base64_variants.ORIGINAL);
        const encryptedData = {
          salt: saltBase64,
          iv: ivBase64,
          encryptedMessage: cipherTextBase64
        };

        const encryptedDataString = JSON.stringify(encryptedData)

        this.notificationWs = new WebSocket(
          `${process.env.VUE_APP_SERVER_URL_NOTIFICATIONS}${this.$cookies.get('ID')}`
        )

        this.testData = encryptedDataString

        this.notificationWs.onopen = () => {
          console.log('WebSocket connected ws')
          this.notificationWs.send(encryptedDataString)
        }
        this.notificationWs.onclose = function () {
          console.log('WebSocket closed')
        }
        this.notificationWs.onerror = function (error) {
          this.testData = error
          console.log('WebSocket error:', error)
        }
        this.notificationWs.onmessage = (event) => {
          this.testData = event.data
          const data = JSON.parse(event.data)
          const audio = new Audio(notificationSound)
          this.push_notifications.push(data)
          audio.play()
          setTimeout(() => {
            this.push_notifications.splice(0, 1)
          }, 4000)
        }
      })()
    },

    show_center_window (value) { this.center_window = value },
    show_modal_window (value) { this.modal_window = value },
    url (url) { return `${process.env.VUE_APP_SERVER_URL}/${url}` },
    logout () {
      this.$store.dispatch('logout')
      this.$router.go({ name: 'Main' })
    },

    handleKeyPress (event) {
      if (event.code === 'F1') {
        this.window_instruction = !this.window_instruction
        event.preventDefault()
      }
    },

    watchTocken () {
      if (this.$cookies.get('JWT')) {
        const tokenData = jwtDecode(this.$cookies.get('JWT'))
        const expirationTime = tokenData.exp;
        const currentTime = Math.floor(Date.now() / 1000);
        this.tokenLifetime = expirationTime - currentTime
        if(this.tokenLifetime < 60) refreshTocken()
      } else if (this.$cookies.get('REFRESH_JWT')) refreshTocken()
      setTimeout(this.watchTocken, 10000)
    },

  },
  mounted () {
    if(this.$cookies.get('ID')) {
      this.$store.commit('connectOnlineUsers')
      this.notificationWsMethod()
    }
    else {
      this.interval = setInterval(() => {
        if(this.$cookies.get('ID')) {
          this.userId = this.$cookies.get('ID')
          this.notificationWsMethod()
          this.$store.commit('connectOnlineUsers')
          clearInterval(this.interval);
        }
      }, 2000);
    }

    if(this.$cookies.get('REFRESH_JWT')) {
      this.watchTocken()
    }
  },
  beforeDestroy() {
    document.removeEventListener('mousemove', this.updateMousePosition);
  },
}
</script>

<style>
  @import "@/assets/css/style.css";
  .notification-content.audio {
    height: auto;
  }
  .notification-content.audio audio {
    margin-top: 10px;
  }
  .instriction {
    position: fixed;
    top: 0px;
    background: rgba(0, 0, 0, 0.856);
    width: 100%;
    height: 100vh;
    z-index: 60;
    overflow-y: scroll;
  }
  .instriction h3,
  .instriction h4,
  .column_main h2 {color: #fff; margin-top: 10px;}
  .list_istruction h2 {cursor: pointer;}
  .list_istruction h2:hover {background: #ECC22C; color: black;}
  .instriction h3 {
    text-align: center;
    font-size: 30px;
    margin-top: 90px;
  }
  .instriction h4 {
    text-align: center;
    font-size: 25px;
    margin-top: 20px;
  }
  .column_main {
    margin-left: 10%;
    padding: 10px;
  }
  .column_main p {
    width: 400px;
    margin-top: 10px;
    color: #fff;
    font-size: 20px;
  }
  .list_istruction a {color: #fff;}
  hr {margin: 10px;}
  .instruction_help {
    position: absolute;
    bottom: 150px;
    right: 100px;
  }
  .instruction_help button {
    width: 200px;
    height: 50px;
    border-radius: 15px;
    border: none;
    background: #ECC22C;
    cursor: pointer;
    font-size: 17px;
  }
  .instruction_help button {
  animation: flashAnimation 1.5s infinite;
}

@keyframes flashAnimation {
  0% {
    background-color: #ECC22C;
  }
  50% {
    background-color: rgb(70, 68, 68);
  }
  100% {
    background-color: #ECC22C;
  }
}
</style>
