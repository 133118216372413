
<template>
  <div class="organization-usersinfo" :class="{'dark-bg-primary': theme === 'dark'}">
    <NavigationLayout></NavigationLayout>
    <div class="organization-usersinfo-content">
      <div class="title" :class="{'dark-text': theme === 'dark'}">Участники:</div>
      <div v-for="user in NotBlockedUsers" :key="user.id" class="organization-management" :class="{'dark-text': theme === 'dark'}">
        <span>{{ user.name }}</span>
        <span>{{ user.surname }}</span>
        <button @click="blockUser(user.trueId)" class="layout-header-btn">Заблокировать</button>
      </div>
      <div v-for="user in nonOrganizationUsers" :key="user.id" class="organization-management" :class="{'dark-text': theme === 'dark'}">
        <span>{{ user.name }}</span>
        <span>{{ user.surname }}</span>
        <button @click="nonOrganizationBlockUser(user.id)" class="layout-header-btn">Заблокировать</button>
      </div>

      <div v-if="organizationUsersWaiting.length" class="title" :class="{'dark-text': theme === 'dark'}">Ожидают вступления:</div>
      <div v-for="user in organizationUsersWaiting" :key="user.id" :class="{'dark-text': theme === 'dark'}">
        <span style="margin-right: 8px;">{{ user.name}} </span>
        <span>{{ user.surname }}</span>
        <div style="display: flex; gap: 12px">
          <button @click="acceptUser(user.trueId)" class="layout-header-btn"> принять</button>
          <button @click="rejectUser(user.trueId)" class="layout-header-btn"> отказать</button>
        </div>
      </div>
      <div v-if="blockedUsers.length" :class="{'dark-text': theme === 'dark'}" style="margin-top: 16px; margin-bottom: 16px">Заблокированные:</div>
      <div v-for="user in blockedUsers" :key="user.id" class="organization-management" :class="{'dark-text': theme === 'dark'}">
        <span>{{ user.name }}</span>
        <span>{{ user.surname }}</span>
        <button @click="unblockUser(user.trueId)" class="layout-header-btn">Разблокировать</button>
      </div>
      <div v-for="user in nonOrganizationUsersBlocked" :key="user.id" class="organization-management" :class="{'dark-text': theme === 'dark'}">
        <span>{{ user.name }}</span>
        <span>{{ user.surname }}</span>
        <button @click="nonOrganizationUnblockUser(user.id)" class="layout-header-btn">Разблокировать</button>
      </div>
    </div>
  </div>
</template>

<script>

/* eslint-disable */

import { getOrganizationByUserId, getOrganizationsUsersAll, getUserById, getTimesheetWorkersNotAuth } from '@/api/get'
import { acceptInviteToOrganization, rejectInviteToOrganization, blockUserInOrganization, unblockUserInOrganization, blockNotOrganizationUser, unblockNotOrganizationUser } from '@/api/patch'

import VueCookies from 'vue-cookies'

import NavigationLayout from '@/components/AllPage/NavigationLayout.vue'

export default {
  components: {
    NavigationLayout
  },
  data () {
    return {
      userId: this.$cookies.get('ID'),
      theme: VueCookies.get('THEME'),
      organizationId: null,
      organizationUsers: [],
      organizationUsersWaiting: [],
      nonOrganizationUsers: [],
      nonOrganizationUsersBlocked: [],
    }
  },
  methods: {
    acceptUser (userId) {
      acceptInviteToOrganization(this.organizationId, userId)
      this.updateOrganization()
    },

    rejectUser (userId) {
      rejectInviteToOrganization(this.organizationId, userId)
      this.updateOrganization()
    },

    blockUser (userId) {
      blockUserInOrganization(userId)
      this.updateOrganization()
    },

    unblockUser (userId) {
      unblockUserInOrganization(userId)
      this.updateOrganization()
    },

    nonOrganizationBlockUser (userId) {
      blockNotOrganizationUser(userId)
        .then(res => {
          if (res) {
            const idx = this.nonOrganizationUsers.findIndex(el => el.id === userId)
            this.nonOrganizationUsers.splice(idx, 1);
          } 
        })
      this.updateOrganization()
    },

    nonOrganizationUnblockUser(userId) {
      unblockNotOrganizationUser(userId)
        .then(res => {
          if (res) {
            const idx = this.nonOrganizationUsersBlocked.findIndex(el => el.id === userId)
            this.nonOrganizationUsersBlocked.splice(idx, 1);
          } 
        })
      this.updateOrganization()
    },

    updateOrganization () {
      this.organizationUsersWaiting = []
      this.organizationUsers = []
      getOrganizationByUserId(this.userId)
        .then((reslt) => {
          this.organizationId = reslt.organization_id
          getOrganizationsUsersAll(this.organizationId)
            .then((result) => {
              // eslint-disable-next-line
              result.map(user => {
                getUserById(user.user_id)
                  .then(res => {
                    const data = { ...res.data, trueId: user.id, is_blocked: user.is_blocked }
                    if (user.status === 'WAITING') this.organizationUsersWaiting.push(data)
                    else if ((user.status === 'ACCEPTED')) this.organizationUsers.push(data)
                  })
              })
            })

          getTimesheetWorkersNotAuth(this.organizationId)
          .then(res => {
            res.data.forEach(el => {
              if (!el.is_blocked) this.nonOrganizationUsers.push(el)
              else this.nonOrganizationUsersBlocked.push(el)
            })
          })
        })
    }
  },
  computed: {
    blockedUsers () {
      return this.organizationUsers.filter(user => user.is_blocked === true)
    },
    NotBlockedUsers () {
      return this.organizationUsers.filter(user => user.is_blocked === false)
    }
  },
  beforeMount () {
    this.updateOrganization()
  }

}

</script>

<style scoped>
  .title {
    margin-top: 24px;
    margin-bottom: 24px;
  }
  .organization-usersinfo {
    min-height: 100vh;
    display: flex;
    justify-content: start !important;
    align-items: start !important;
  }
  .organization-management {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
  }

  @media screen and (max-width: 768px) {
    .organization-usersinfo {
      padding-top: 42px;
    }
  
  }
</style>
