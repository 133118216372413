import { createStore } from 'vuex'
import { cookies } from '@/api/index'
// import { getChatsList } from '@/api/get'
import { getChatsList, getOrganizationByUserId } from '@/api/get'
import VueCookies from 'vue-cookies'

const store = createStore({
  namespaced: true,
  state: {
    DATA_USER: {},
    ROLE_URL: 'LkUser',
    LIST_USERS: [],
    LIST_APPEALS: [],
    LIST_ROLES: [],
    LIST_DIRECTIONS: [],
    LIST_ESTIMATES: [],
    LIST_DEPARTAMENTS: [],
    LIST_EMPLOYEES: [],
    LIST_COMMENTS: [],
    LIST_CHATS: [],
    LIST_CHATS_WS: [],
    ACTIVE_CHAT_MESSAGES: [],
    LIST_NOTIFICATIONS: [],

    activechats: [],
    ONLINE_USERS: [],
    wsOnline: null,
    wsOnlineInterval: null
  },
  actions: {
    setDataUser ({ commit }, data) {
      cookies.set('ID', data.id)
      this.state.DATA_USER = data
      // if (data.role_title === 'Администратор') {
      //   this.state.ROLE_URL = 'LkSysAdmin/Users'
      // } else if (data.role_title === 'Мастер') {
      //   this.state.ROLE_URL = 'LkMaster/UserMonitor'
      // } else if (data.role_title === 'Клиент') {
      //   this.state.ROLE_URL = 'LkUser'
      // }
    },
    logout ({ commit }) {
      this.state.wsOnline.close()
      clearInterval(this.state.wsOnlineInterval)
      let darkTheme = false
      if (VueCookies.get('THEME') === 'dark') {
        console.log('dark')
        darkTheme = true
      }

      cookies.keys().forEach((key) => {
        cookies.remove(key)
      })

      if (darkTheme) VueCookies.set('THEME', 'dark')

      localStorage.clear()
      this.state.DATA_USER = {}
      this.state.ROLE_URL = 'LkUser'
    }
  },
  mutations: {
    connectChats (state) {
      state.LIST_CHATS_WS.forEach(el => { el.close() })
      state.LIST_CHATS_WS = []

      if (cookies.get('ID')) {
        getOrganizationByUserId(cookies.get('ID'))
          .then(res => {
            getChatsList(false, res.organization_id, cookies.get('ID'))
              .then(response => {
                state.LIST_CHATS = response
                for (let i = 0; i < state.LIST_CHATS?.length; i++) {
                  const ws = new WebSocket(
                    `${process.env.VUE_APP_SERVER_URL_CHAT_ACTIVE}${state.LIST_CHATS[i].id}/${cookies.get('ID')}`
                  )
                  ws.onopen = function () {
                    console.log('WebSocket connected chats')
                  }
                  ws.onerror = function (error) {
                    console.log('WebSocket error:', error)
                  }
                  ws.onclose = function () {
                    console.log('WebSocket closed chats')
                  }
                  ws.onmessage = (event) => {
                    const message = JSON.parse(event.data)
                    if (message) state.ACTIVE_CHAT_MESSAGES.unshift(message)
                  }
                  state.LIST_CHATS_WS.push(ws)
                }
              })
          })
      }
    },

    connectOnlineUsers () {
      if (!cookies.get('ID')) return

      this.state.wsOnline = new WebSocket(
        `${process.env.VUE_APP_SERVER_URL_ONLINE}${cookies.get('ID')}`
      )

      this.state.wsOnline.onopen = function () {
        console.log('WebSocket connected')
        store.state.wsOnline.send('get')
      }

      this.state.wsOnlineInterval = setInterval(() => {
        this.state.wsOnline.send('get')
      }, 8000)

      this.state.wsOnline.onerror = function (error) {
        console.log('WebSocket error:', error)
      }

      this.state.wsOnline.onclose = function () {
        console.log('WebSocket closed')
      }

      this.state.wsOnline.onmessage = (event) => {
        const message = JSON.parse(event.data)
        store.state.ONLINE_USERS = message
      }
    },

    updateUserStatus (state, newStatus) {
      state.userStatus = newStatus
    }
  }
})

export default store
