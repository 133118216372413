import { createRouter, createWebHistory } from 'vue-router'
import { getUserActive } from '@/api/get'
import { cookies } from '@/api/index'
import UserDashBoardComp from '@/components/WorkSpace/UserDashBoardComp.vue'
import OrganizationManagement from '@/views/OrganizationManagement.vue'

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('@/views/MainView.vue')
  },
  {
    path: '/messenger',
    name: 'Messenger',
    component: () => import('@/views/MessengerView.vue'),
    meta: { auth: true }
  },
  {
    path: '/workspace',
    name: 'Workspace',
    component: () => import('@/views/WorkSpaceView.vue'),
    children: [
      {
        path: '/Workspace/PersonalDashboard',
        name: 'Workspace/PersonalDashboard',
        component: UserDashBoardComp,
        props: true
      }
    ],
    meta: { auth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/reccovery',
    name: 'Reccovery',
    component: () => import('@/views/ReccoveryPassword.vue')
  },
  {
    path: '/reccovery_new',
    name: 'Reccovery2',
    component: () => import('@/views/ReccoveryPassword2.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/RegisterView.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/views/SettingsView.vue'),
    meta: { auth: true }
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('@/views/Account.vue'),
    meta: { auth: true }
  },
  {
    path: '/organization_management',
    name: 'organization_management',
    component: OrganizationManagement,
    meta: { auth: true }
  },
  {
    path: '/organization_user_info',
    name: 'organization_user_info',
    component: () => import('@/views/OrganizationUsersInfo.vue'),
    meta: { auth: true }
  },
  {
    path: '/user_table',
    name: 'user_table',
    component: () => import('@/views/UserTable.vue'),
    meta: { auth: true }
  },
  {
    path: '/organization_table',
    name: 'organization_table',
    component: () => import('@/views/OrganizationTable.vue'),
    meta: { auth: true }
  },
  {
    path: '/organization_table_list',
    name: 'organization_table_list',
    component: () => import('@/views/OrganizationTableList.vue'),
    meta: { auth: true }
  },
  {
    path: '/transactions',
    name: 'transactions',
    component: () => import('@/views/Transactions.vue'),
    meta: { auth: true }
  },
  {
    path: '/transactions_reports',
    name: 'transactions_reports',
    component: () => import('@/views/TransactionsReports.vue'),
    meta: { auth: true }
  },
  {
    path: '/tasks_list',
    name: 'Tasks_list',
    component: () => import('@/views/TaskListView.vue'),
    meta: { auth: true },
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (cookies.get('JWT')) getUserActive()
  if (to.matched.some((record) => record.meta.auth)) {
    if (cookies.get('JWT')) next()
    else next('/')
  } else next()
})

export default router
