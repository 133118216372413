<template>
  <div v-if="notifications_visible" class="notifications">
    <div class="notifications_body">
      <div class="notifcations_header">
        <a id="check_a" @click="check_all_notifications">
          <img src='@/assets/images/NotificationsCenter/NotificationsCheckAll.png' alt="notification">
        </a>
        <p>Уведомления</p>
        <button @click="changeNotificationsVisible" class="button-close" >×</button>
      </div>
      <div class="notifications_overflow">
        <div
          v-for="item in new_notifications"
          :key="item.id" id="item_div"
          :class="{'notification_unchecked': !item.is_checked, 'notification_checked': item.is_checked}"
          @click="check_notification(item)"
        >
          <img src="@/assets/images/NotificationsCenter/NotificationsTypeTask.svg">
          <p>{{ item.notification_data.notification_text }} </p>
        </div>
        <div
          v-for="item in viewed_notifications"
          :key="item.id" id="item_div"
          :class="{'notification_unchecked': !item.is_checked, 'notification_checked': item.is_checked}"
          @click="check_notification(item)"
        >
          <img src="@/assets/images/NotificationsCenter/NotificationsTypeTask.svg">
          <p>{{ item.notification_data.notification_text }} </p>
        </div>
      </div>
    </div>
  </div>

  <div v-if="popupVisible" class="popup" >
    <div class="popup-body" :class="{'dark-bg-primary': theme === 'dark'}">
      <div @click="changePopupVisible" class="layout-logo" :class="{'dark-bg-primary': theme === 'dark'}">
        <img src="@/assets/images/menu.png">
        <span>smartsoft</span>
      </div>

      <router-link
        class="nav-link"
        :class="{'layout-nav-active': active == 'main', 'dark-layout-nav-active': (theme === 'dark' && active == 'main')}"
        :to="{ name: 'Main' }"
      >
        <img src="@/assets/images/navigation/nav-home.png">
        <span :class="{'dark-text': theme === 'dark'}">Главная</span>
      </router-link>

      <router-link
        class="nav-link"
        :class="{'layout-nav-active': active == 'workSpace','dark-layout-nav-active': theme === 'dark' && active == 'workSpace'}"
        :to="{ name: 'Workspace/PersonalDashboard' }"
      >
        <img src="@/assets/images/navigation/nav-view-table.png">
        <span :class="{'dark-text': theme === 'dark'}">Доска задач </span>
      </router-link>

      <router-link
        class="nav-link"
        :class="{'layout-nav-active': active == 'table','dark-layout-nav-active': theme === 'dark' && active == 'table'}"
        :to="{ name: 'organization_table_list' }"
      >
        <img src="@/assets/images/navigation/nav-table.svg">
        <span :class="{'dark-text': theme === 'dark'}">Табель</span>
      </router-link>

      <router-link
        class="nav-link"
        :class="{'layout-nav-active': active == 'transactions','dark-layout-nav-active': theme === 'dark' && active == 'transactions'}"
        :to="{ name: 'transactions' }"
      >
        <img src="@/assets/images/navigation/nav-transactions.svg">
        <span :class="{'dark-text': theme === 'dark'}">Бухгалтерия</span>
      </router-link>

      <router-link
        class="nav-link"
        :class="{'layout-nav-active': active == 'transactions','dark-layout-nav-active': theme === 'dark' && active == 'transactions'}"
        :to="{ name: 'Messenger' }"
      >
        <img src="@/assets/images/navigation/nav-chat-zero.png">
        <span :class="{'dark-text': theme === 'dark'}">Чат</span>
      </router-link>

      <a
        class="nav-link"
        href="/Account"
        :class="{'layout-nav-active': active == 'account','dark-layout-nav-active': theme === 'dark' && active == 'account'}"
      >
        <img :src="getImgUrl(user_photo)" alt="photo" class="nav-img-visible" style="margin-left: -2px;"/>
        <span :class="{'dark-text': theme === 'dark'}">Учетная запись</span>
      </a>
    </div>
  </div>

  <div class="layout" :class="{'dark-bg-secondary': theme === 'dark'}">
    <div class="layout-header">
      <div @click="changePopupVisible" class="layout-logo" :class="{'dark-bg-primary': theme === 'dark'}">
        <img src="@/assets/images/menu.png">
        <span>smartsoft</span>
      </div>
      <ul class="layout-nav">
        <router-link
          class="nav-link"
          :class="{'layout-nav-active': active == 'main', 'dark-layout-nav-active': (theme === 'dark' && active == 'main')}"
          :to="{ name: 'Main' }"
        >
          <img src="@/assets/images/navigation/nav-home.png">
          <span :class="{'dark-text': theme === 'dark'}">Главная</span>
        </router-link>

        <router-link
          class="nav-link"
          :class="{'layout-nav-active': active == 'workSpace','dark-layout-nav-active': theme === 'dark' && active == 'workSpace'}"
          :to="{ name: 'Workspace/PersonalDashboard' }"
        >
          <img src="@/assets/images/navigation/nav-view-table.png">
          <span :class="{'dark-text': theme === 'dark'}">Доска задач</span>
        </router-link>

        <router-link
          class="nav-link"
          :class="{'layout-nav-active': active == 'table','dark-layout-nav-active': theme === 'dark' && active == 'table'}"
          :to="{ name: 'organization_table_list' }"
        >
          <img src="@/assets/images/navigation/nav-table.svg">
          <span :class="{'dark-text': theme === 'dark'}">Табель</span>
        </router-link>

        <router-link
          class="nav-link"
          :class="{'layout-nav-active': active == 'transactions','dark-layout-nav-active': theme === 'dark' && active == 'transactions'}"
          :to="{ name: 'transactions' }"
        >
          <img src="@/assets/images/navigation/nav-transactions.svg">
          <span :class="{'dark-text': theme === 'dark'}">Бухгалтерия</span>
        </router-link>

        <router-link
          class="nav-link"
          :class="{'layout-nav-active': active == 'messenger','dark-layout-nav-active': theme === 'dark' && active == 'messenger'}"
          :to="{ name: 'Messenger' }"
        >
          <img src="@/assets/images/navigation/nav-chat-zero.png">
          <span :class="{'dark-text': theme === 'dark'}">Чат</span>
        </router-link>

      </ul>
    </div>
    <div class="layout-footer">
      <ul class="layout-nav">
        <a @click="changeNotificationsVisible" class="nav-link">
          <img v-if="new_notifications.length" src="@/assets/images/navigation/nav-notifications.png" class="nav-img-visible">
          <img v-else src="@/assets/images/navigation/nav-notifications-zero.png" class="nav-img-visible">
          <span :class="{'dark-text': theme === 'dark'}">Уведомления</span>
        </a>
        <a
          class="nav-link"
          href="/Account"
          :class="{'layout-nav-active': active == 'account','dark-layout-nav-active': theme === 'dark' && active == 'account'}"
        >
          <img :src="getImgUrl(user_photo)" width="24" height="24" alt="photo" class="nav-img-visible" style="margin-left: -2px;"/>
          <span :class="{'dark-text': theme === 'dark'}">Учетная запись</span>
        </a>
      </ul>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import VueCookies from 'vue-cookies'
import { getUserNotifications, getOrganizationMember, getUserActive } from '@/api/get'
import { acceptUserNotification } from '@/api/patch'
import { getUserTasks, getCreatorTasks } from '@/api/post'
import getImgUrl from '@/mixins/getImgUrl.vue'
export default {
  mixins: [getImgUrl],
  data () {
    return {
      userId: '',
      myCookie: this.$cookies.get('JWT'),
      theme: VueCookies.get('THEME'),
      tasks: [],
      notifications: [],
      new_notifications: [],
      viewed_notifications: [],
      notifications_visible: false,
      profile_visilbe: false,
      user_photo: '',
      popupVisible: false
    }
  },
  props: {
    active: String
  },
  methods: {
    check_API () {
      getUserNotifications().then((response) => {
        this.new_notifications = response.filter(el => !el.is_checked)
        this.viewed_notifications = response.filter(el => el.is_checked)
        this.new_notifications.sort((a, b) => new Date(b.notification_data.notification_send_time) - new Date(a.notification_data.notification_send_time))
        this.viewed_notifications.sort((a, b) => new Date(b.notification_data.notification_send_time) - new Date(a.notification_data.notification_send_time))
      })
    },

    async fetchTasks () {
      try {
        let tasks = []
        const filterType = 'user'
        const { data } = await getOrganizationMember(this.$cookies.get('ID'))
        const organizationId = data.organization_id
        if (filterType === 'user') {
          const { data } = await getUserTasks(this.$cookies.get('ID'), organizationId)
          tasks = data
        } else if (filterType === 'me_creator') {
          const { data } = await getCreatorTasks(this.createrID, organizationId)
          tasks = data
        }
        this.tasks = tasks
      } catch (error) {
        this.error = true
      }
    },
    checkDeadlineTasks () {

    },

    check_all_notifications () {
      for (const notification of this.new_notifications) this.check_notification(notification)
      this.new_notifications = []
    },

    check_notification (notification) {
      acceptUserNotification(notification.notification_data.notification_id)
        .then(res => this.check_API())
    },

    changeNotificationsVisible () {
      this.notifications_visible = !this.notifications_visible
    },

    changeProfileVisible () {
      this.profile_visilbe = !this.profile_visilbe
    },

    changePopupVisible() {
      this.popupVisible = !this.popupVisible
    }
  },

  computed: {
    notificationsHasNew() {
      const res = this.notifications.find(el => el.is_checked === false)
      if (res) return true
      return false
    }
  },

  mounted () {
    this.check_API()
    if (this.myCookie) this.interval = setInterval(this.check_API, 6000)
    getUserActive()
      .then(res => {
        this.user_photo = res.photo_url
      })

    if (this.myCookie) this.intervalTasks = setInterval(this.checkDeadlineTasks, 5000)
    else {
      clearInterval(this.interval)
      clearInterval(this.intervalTasks)
    }
  },

  beforeUnmount () {
    clearInterval(this.interval)
    clearInterval(this.intervalTasks)
  }
}
</script>
